import React, { useState, lazy } from 'react';
import Modal from 'react-modal';
//@ts-ignore
import * as styles from './video-player.module.css';
import PlayArrow from '../PlayArrow';
import CloseButton from '../CloseButton';
import loadable from '@loadable/component';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

const ReactPlayer = loadable(() => import('react-player'));

interface Props {
    src: string;
    thumbnail?: string | boolean;
}

const VideoPlayer: React.FC<Props> = ({ src, thumbnail = true }) => {
    const breakpoints = useBreakpoint();
    const isMobile = !breakpoints.sm;
    const [modalOpen, setModalOpen] = useState(false);
    const handleCloseClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        setModalOpen(false);
    };
    const customModalStyles = {
        overlay: {
            zIndex: 1000,
        },
    };

    return (
        <div
            className={styles.wrapper}
            style={{
                paddingTop: '56.25%',
            }}
        >
            <ReactPlayer
                url={src}
                light={thumbnail}
                playIcon={
                    <div className={styles.playArrow}>
                        <PlayArrow heightWidth="4rem" strokeColor="#ffffff" />
                    </div>
                }
                style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                }}
                height="100%"
                width="100%"
                onClickPreview={() => setModalOpen(true)}
            />
            <Modal
                isOpen={modalOpen}
                onRequestClose={() => setModalOpen(false)}
                contentLabel="Video Player"
                className={`${styles.modalContent} ${
                    isMobile
                        ? styles.mobilemodalContent
                        : styles.desktopmodalContent
                }`}
                style={customModalStyles}
            >
                <div className={styles.closeButton}>
                    <CloseButton
                        visualStyle="orange"
                        onClick={handleCloseClick}
                    />
                </div>

                <div
                    className={styles.modalPlayerWrapper}
                    style={{
                        paddingTop: '56.25%',
                    }}
                >
                    <ReactPlayer
                        url={src}
                        style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                        }}
                        height={isMobile ? '100%' : '80vh'}
                        width="100%"
                        controls
                        playing
                    />
                </div>
            </Modal>
        </div>
    );
};

export default VideoPlayer;
