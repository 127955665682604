import React, { useContext } from 'react';
import {
    KontentRichText,
    KontentStringValue,
} from '../../../types/KontentProps';
//@ts-ignore
import { RichTextElement } from '@kontent-ai/gatsby-components';
import { DomElement, domToReact } from 'html-react-parser';
import QuotedText from '../QuotedText';
import Headline from '../Headline';
import RteComponent from '../RteComponent';
import BlogContentRendering from '../BlogContentRendering';
import * as headlineStyles from '../Headline/headline.module.css';
import ReferenceComponent from '../ReferenceComponent';
import { getMediaData, getMultiChoice } from './DataUtils';
import VideoPlayer from './VideoPlayer';
import { PageMetadataContext } from '../../templates/PageMetaDataContext';
import { defaultDomainCountryCodes } from '../../global/countries';

type Image = {
    url: string;
    description?: string;
    name?: string;
};

type Link = {
    codename: string;
    url_slug: string;
    type: string;
    link_id: string;
};

interface KontentItemComponent {
    __typename: string;
    elements: any;
    system: {
        type: string;
    };
}

interface PagePaths {
    [key: string]: string;
}

const RichTextContainer = ({
    richText,
    pagePaths,
    preferredLanguage,
    giveID = false,
    panelName = undefined,
}: {
    richText: KontentRichText;
    pagePaths: PagePaths;
    preferredLanguage: string;
    giveID?: boolean | undefined;
    panelName?: KontentStringValue | undefined;
}) => {
    if (!richText) return null;

    return (
        <div style={{ overflow: 'auto', clear: 'both' }}>
            <RichTextElement
                value={richText.value}
                images={richText.images}
                resolveImage={(image: Image) => {
                    return (
                        <img
                            src={image?.url}
                            alt={
                                image?.description
                                    ? image?.description
                                    : image?.name
                            }
                        />
                    );
                }}
                links={richText.links}
                resolveLink={(link: Link, domNode: DomElement) => {
                    let path = link?.url_slug;

                    if (pagePaths) {
                        path = pagePaths[link?.url_slug];
                    }

                    const linkHref =
                        defaultDomainCountryCodes.indexOf(
                            preferredLanguage.toLowerCase()
                        ) > -1 ||
                        path?.split('/')[1] == preferredLanguage.toLowerCase()
                            ? path
                            : `/${preferredLanguage.toLowerCase()}${path}`;

                    if (domNode?.children && path) {
                        return (
                            <a
                                href={linkHref}
                                id={
                                    giveID && panelName?.value
                                        ? domToReact(domNode.children)?.type ===
                                          'strong'
                                            ? panelName.value.replace(
                                                  / /g,
                                                  '-'
                                              ) +
                                              '__' +
                                              domToReact(domNode.children)
                                                  ?.props?.children?.toString()
                                                  ?.replace(/ /g, '-')
                                            : panelName.value?.replace(
                                                  / /g,
                                                  '-'
                                              ) +
                                              '__' +
                                              domToReact(domNode.children)
                                                  ?.toString()
                                                  ?.replace(/ /g, '-')
                                        : undefined
                                }
                            >
                                {domToReact(domNode.children)}
                            </a>
                        );
                    }
                }}
                linkedItems={richText.modular_content}
                resolveLinkedItem={(linkedItem: KontentItemComponent) => {
                    // Debug
                    // return (
                    //     <pre>{JSON.stringify(linkedItem, undefined, 2)}</pre>
                    // );
                    switch (linkedItem.system.type) {
                        case 'component___quoted_text':
                            return <QuotedText {...linkedItem.elements} />;
                        case 'component___headline':
                            return (
                                <Headline
                                    {...linkedItem.elements}
                                    overrideStyles={{
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        paddingLeft: '0',
                                        paddingRight: '0',
                                    }}
                                />
                            );
                        case 'component___rte':
                            return (
                                <RteComponent
                                    {...linkedItem.elements}
                                    overrideStyles={{
                                        margin: 'auto',
                                        maxWidth: '100%',
                                        paddingLeft: '0',
                                        paddingRight: '0',
                                    }}
                                />
                            );
                        case 'component___blog_content':
                            return (
                                <BlogContentRendering
                                    {...linkedItem.elements}
                                />
                            );
                        case 'rte___floating_image':
                            const imgData = getMediaData(
                                linkedItem.elements.image
                            );
                            const float = getMultiChoice(
                                linkedItem.elements.image_float_position
                            );
                            let padding =
                                float === 'left'
                                    ? '0 2rem 0 0'
                                    : float && '0 0 0 2rem';

                            const authorStyles = {
                                float: float,
                                width: 'auto',
                                maxWidth: '100%',
                                padding: padding || '1rem',
                                clear: 'both',
                            };
                            return (
                                <img
                                    src={imgData?.url}
                                    alt={imgData?.description || imgData?.name}
                                    //@ts-ignore
                                    style={authorStyles}
                                />
                            );
                        case 'media___image':
                            const medImageData =
                                linkedItem.elements?.file?.value[0];

                            return (
                                <img
                                    src={medImageData?.url}
                                    alt={
                                        medImageData?.description ||
                                        medImageData?.name
                                    }
                                />
                            );
                        case 'media___general_video':
                            const src =
                                linkedItem?.elements?.youtube_url?.value;

                            return <VideoPlayer src={src} />;
                        default:
                            // When using Storybook in the Dev environemnt, the use of ReferenceComponent needs to be commented out.
                            // return (
                            //     <ReferenceComponent
                            //         name={linkedItem.__typename}
                            //         {...linkedItem}
                            //     />
                            // );
                            return (
                                <p style={{ color: '#f06623' }}>
                                    <strong>{linkedItem.system.type}</strong>{' '}
                                    component not available in rich text
                                    element. To correct, add component to{' '}
                                    <code>RichText</code> function or return{' '}
                                    <code>ReferenceComponent</code> by default
                                    in{' '}
                                    <code>
                                        src/components/Global/RichText.tsx
                                    </code>
                                    .
                                </p>
                            );
                    }
                }}
                resolveDomNode={(domNode: DomElement, domToReact: Function) => {
                    // For options - check https://www.npmjs.com/package/html-react-parser#options
                    if (domNode.name === 'h1') {
                        return (
                            <div className={headlineStyles.h1}>
                                {domToReact(domNode.children)}
                            </div>
                        );
                    }

                    if (domNode.name === 'h2') {
                        return (
                            <div className={headlineStyles.h2}>
                                {domToReact(domNode.children)}
                            </div>
                        );
                    }

                    if (domNode.name === 'h3') {
                        return (
                            <div className={headlineStyles.h3}>
                                {domToReact(domNode.children)}
                            </div>
                        );
                    }

                    if (domNode.name === 'h4') {
                        return (
                            <div className={headlineStyles.h4}>
                                {domToReact(domNode.children)}
                            </div>
                        );
                    }
                }}
            />
        </div>
    );
};

const RichText = (
    richText: KontentRichText,
    giveID: boolean | undefined,
    panelName: KontentStringValue | undefined
) => {
    const pageMetadata = useContext(PageMetadataContext);
    const pagePaths = pageMetadata?.pagePaths;
    const preferredLanguage = pageMetadata.preferredLanguage || 'default';

    return (
        <RichTextContainer
            richText={richText}
            pagePaths={pagePaths}
            preferredLanguage={preferredLanguage}
            giveID={giveID}
            panelName={panelName}
        />
    );
};

export default RichText;

interface RichTextElProps {
    richText: KontentRichText;
    giveID?: boolean | undefined;
    panelName?: KontentStringValue | undefined;
}

const RichTextEl: React.FC<RichTextElProps> = ({
    richText,
    giveID = false,
    panelName = undefined,
}) => {
    const pageMetadata = useContext(PageMetadataContext);
    const pagePaths = pageMetadata?.pagePaths;
    const preferredLanguage = pageMetadata.preferredLanguage || 'default';

    return (
        <RichTextContainer
            richText={richText}
            pagePaths={pagePaths}
            preferredLanguage={preferredLanguage}
            giveID={giveID}
            panelName={panelName}
        />
    );
};

export { RichTextEl };
