import React from 'react';
import { graphql } from 'gatsby';
import {
    BrandColor,
    KontentRadioMultiChoice,
    KontentRichText,
    KontentStringValue,
} from '../../../types/KontentProps';
import RichText from '../Global/RichText';
//@ts-ignore
import * as styles from './RteComponent.module.css';
import { getColor, getMultiChoice } from '../Global/DataUtils';

type Alignment = 'left' | 'right' | 'center' | undefined;

interface Props {
    content: KontentRichText;
    paragraph_copy_color: BrandColor;
    overrideStyles?: React.CSSProperties;
    content_alignment?: KontentRadioMultiChoice<Alignment>;
    anchor_text?: KontentStringValue;
}

const RteComponent: React.FC<Props> = ({
    content,
    paragraph_copy_color,
    overrideStyles,
    content_alignment,
    anchor_text,
}) => {
    const anchorText = anchor_text?.value ? anchor_text?.value : '';
    const fontColor = getColor(paragraph_copy_color) || '#505251';
    const contentAlignment = getMultiChoice(content_alignment) || 'left';

    const authorStyles = overrideStyles
        ? { color: fontColor, textAlign: contentAlignment, ...overrideStyles }
        : { color: fontColor, textAlign: contentAlignment };

    return (
        <div className={styles.RteComponent} style={authorStyles} id={anchorText}>
            {RichText(content)}
        </div>
    );
};

export default RteComponent;

export const fragmentRteComponent = graphql`
    fragment RteComponent on kontent_item_component___rte {
        system {
            codename
        }
        elements {
            content {
                images {
                    description
                    url
                    image_id
                }
                links {
                    codename
                    url_slug
                    type
                    link_id
                }
                value
                ...kontentModularContent
            }
            paragraph_copy_color {
                ...kontentColor
            }
            content_alignment {
                value {
                    codename
                }
            }
            anchor_text {
                value
            }
        }
    }
`;
