import React from 'react';

interface Props {
    strokeColor: string;
    heightWidth?: string;
}
const PlayArrow: React.FC<Props> = ({ heightWidth, strokeColor, ...props }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 131.82 131.82"
            height={heightWidth}
            width={heightWidth}
            {...props}
        >
            <defs>
                <style>
                    {`.Video_Play_Large_svg__cls-1{fill:none; stroke:${strokeColor}; stroke-linecap:round; stroke-linejoin:round; stroke-width:3px;}`}
                </style>
            </defs>
            <g id="Video_Play_Large_svg__Layer_2" data-name="Layer 2">
                <g id="Video_Play_Large_svg__artwork">
                    <path
                        className="Video_Play_Large_svg__cls-1"
                        d="M53.49 32.65l33.26 33.26-33.26 33.26"
                    />
                    <circle
                        className="Video_Play_Large_svg__cls-1"
                        cx={65.91}
                        cy={65.91}
                        r={64.91}
                    />
                </g>
            </g>
        </svg>
    );
};

export default PlayArrow;
