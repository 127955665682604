import React, { useState } from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './quoted-text.module.css';
import {
    KontentRichText,
    KontentStringValue,
    KontentImageUrl,
    KontentRadioMultiChoice,
    BrandColor,
} from '../../../types/KontentProps';
import { getColor, getMultiChoice } from '../Global/DataUtils';

type QuoteType = 'pipe' | 'traditional';
interface Props {
    id?: string;
    pipe_color: BrandColor;
    quote_icon: any;
    style: KontentRadioMultiChoice<QuoteType>;
    quote_content: KontentRichText;
    attribution_title?: KontentStringValue;
    attribution_name?: KontentStringValue;
}

const QuotedText: React.FC<Props> = ({
    pipe_color,
    quote_icon,
    style,
    quote_content,
    attribution_title,
    attribution_name,
}) => {
    const quoteCopy = quote_content.value;
    const pipeColor = getColor(pipe_color);
    const attributionName = attribution_name?.value;
    const attributionTitle = attribution_title?.value;
    const topImageUrl =
        quote_icon?.value[0]?.elements?.icon_svg_file.value[0]?.url;
    const bottomImageUrl =
        quote_icon?.value[1]?.elements?.icon_svg_file.value[0]?.url;
    const quoteOption = getMultiChoice(style);

    let quoteTypeStyle = setQuoteType(quoteOption, pipeColor);

    return (
        <div className={styles.wrapper}>
            {quoteOption === 'pipe' && (
                <div className={styles.content}>
                    <div className={styles.pipe} style={quoteTypeStyle}>
                        <p>{quoteCopy}</p>
                        {attributionName && (
                            <p className={styles.author}>
                                {attributionName} <br />
                                <span className={styles.title}>
                                    {attributionTitle}
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            )}
            {quoteOption === 'traditional' && (
                <div className={styles.quote} style={quoteTypeStyle}>
                    {topImageUrl && (
                        <div className={styles.quoteImg}>
                            {<img src={topImageUrl} />}
                        </div>
                    )}
                    <p>{quoteCopy}</p>
                    {attributionName && (
                        <p className={styles.author}>
                            {attributionName} <br />
                            <span className={styles.title}>
                                {attributionTitle}
                            </span>
                        </p>
                    )}
                    {bottomImageUrl && (
                        <div className={styles.quoteImgBottom}>
                            <img src={bottomImageUrl} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default QuotedText;

function setQuoteType(quoteType: string, pipeColor: string) {
    if (quoteType === 'pipe') {
        const pipeStyle: React.CSSProperties = {
            borderLeft: '2px solid ' + pipeColor,
        };

        return pipeStyle;
    } else {
        const quoteStyles: React.CSSProperties = {
            borderLeft: '0',
            textAlign: 'center',
        };
        return quoteStyles;
    }
}

export const fragmentQuotedText = graphql`
    fragment QuotedText on kontent_item_component___quoted_text {
        id
        system {
            codename
        }
        elements {
            attribution_name {
                value
            }
            attribution_title {
                value
            }
            pipe_color {
                value {
                    ... on kontent_item_list___brand_colors {
                        elements {
                            color_hex_value {
                                value
                            }
                            color_name {
                                value
                            }
                        }
                    }
                }
            }
            quote_content {
                value
            }
            quote_icon {
                value {
                    ... on kontent_item_media___icon {
                        id
                        elements {
                            icon_svg_file {
                                value {
                                    url
                                }
                            }
                            color {
                                value {
                                    codename
                                }
                            }
                        }
                    }
                }
            }
            style {
                value {
                    codename
                }
            }
        }
    }
`;
