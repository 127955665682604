import React from 'react';
import { graphql } from 'gatsby';
//@ts-ignore
import * as styles from './blog-content-rendering.module.css';
import RichText from '../Global/RichText';
//@ts-ignore
import { KontentRichText } from '../../../types/KontentProps';

interface Props {
    content?: KontentRichText;
}

const BlogContentRendering: React.FC<Props> = ({ content }) => {
    if (!content?.value) return null;

    return <section className={styles.wrapper}>{RichText(content)}</section>;
};
export default BlogContentRendering;

export const fragmentBlogContent = graphql`
    fragment BlogContent on kontent_item_component___blog_content {
        elements {
            content {
                ...kontentRichText
            }
        }
    }
`;
